<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col offset-md="3" cols="2" sm="2" md="2">
          <v-checkbox
              v-model="item.showOnFirstPage"
              :error-messages="showOnFirstPageErrors"
              :label="$t(`Mettre en avant sur la page d'accueil`)"
              @input="$v.item.showOnFirstPage.$touch()"
              @blur="$v.item.showOnFirstPage.$touch()"
          />
        </v-col>
        <v-col cols="4" sm="4" md="4">
          <v-text-field
              v-model="item.nom"
              :error-messages="nomErrors"
              :label="$t('Nom')"
              @input="$v.item.nom.$touch()"
              @blur="$v.item.nom.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-checkbox
              v-model="item.inviteImportant"
              :error-messages="inviteImportantErrors"
              :label="$t('Invité important')"
              @input="$v.item.inviteImportant.$touch()"
              @blur="$v.item.inviteImportant.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <media-object v-model="item.mediaObjectImage" is-image max-height="25vh"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
              v-model="item.intervenantType"
              :items="intervenantTypeSelectItems"
              :error-messages="intervenantTypeErrors"
              :no-data-text="$t('No results')"
              :label="$t(`Type d'intervenant`)"
              :item-value="'@id'"
              :item-text="'nom'"
              unique
              chips
              persistent-hint
              item-text="nom"
              item-value="@id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
              v-model="item.evenement"
              :items="evenementsSelectItems"
              :error-messages="evenementsErrors"
              :no-data-text="$t('No results')"
              :label="$t('Evenement')"
              unique
              clearable
              item-text="nom"
              item-value="@id"
              chips
              close
              @click:close="chips = false"
              return-object

          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
              v-model="item.siteWeb"
              :error-messages="siteWebErrors"
              :label="$t('Site Web')"
              clearable
              @input="$v.item.siteWeb.$touch()"
              @blur="$v.item.siteWeb.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
              v-model="item.facebook"
              :error-messages="facebookErrors"
              :label="$t('Facebook')"
              @input="$v.item.facebook.$touch()"
              @blur="$v.item.facebook.$touch()"
              clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
              v-model="item.twitter"
              :error-messages="twitterErrors"
              :label="$t('Twitter')"
              @input="$v.item.twitter.$touch()"
              @blur="$v.item.twitter.$touch()"
              clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
              v-model="item.youtube"
              :error-messages="youtubeErrors"
              :label="$t('Youtube')"
              @input="$v.item.youtube.$touch()"
              @blur="$v.item.youtube.$touch()"
              clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
              v-model="item.descriptionCourt"
              :error-messages="descriptionCourtErrors"
              :label="$t('Description courte')"
              @input="$v.item.descriptionCourt.$touch()"
              @blur="$v.item.descriptionCourt.$touch()"
              clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <wysiwyg
              v-model="item.descriptionComplet"
              :error-messages="descriptionCompletErrors"
              :label="$t('Description complète')"
              @input="$v.item.descriptionComplet.$touch()"
              @blur="$v.item.descriptionComplet.$touch()"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";
import MediaObject from "../MediaObject";

export default {
  name: "IntervenantForm",
  components: {MediaObject},
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {
      }
    },

    initialValues: {
      type: Object,
      default: () => {
      }
    }
  },
  mounted() {
    this.evenementsGetSelectItems();
    this.intervenantTypeGetSelectItems();

  },
  data() {
    return {
      slug: null,
      nom: null,
      motcles: null,
      descriptionCourt: null,
      descriptionComplet: null,
      inviteImportant: null,
      intervenantType: null,
      siteWeb: null,
      facebook: null,
      twitter: null,
      youtube: null,
      showOnFirstPage: null,
      image: null
    };
  },
  computed: {
    ...mapFields("evenement", {
      evenementsSelectItems: "selectItems"
    }),
    ...mapFields("intervenantType", {
      intervenantTypeSelectItems: "selectItems"
    }),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    slugErrors() {
      const errors = [];

      if (!this.$v.item.slug.$dirty) return errors;

      has(this.violations, "slug") && errors.push(this.violations.slug);

      return errors;
    },
    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, "nom") && errors.push(this.violations.nom);

      return errors;
    },
    motclesErrors() {
      const errors = [];

      if (!this.$v.item.motcles.$dirty) return errors;

      has(this.violations, "motcles") && errors.push(this.violations.motcles);

      return errors;
    },
    descriptionCourtErrors() {
      const errors = [];

      if (!this.$v.item.descriptionCourt.$dirty) return errors;

      has(this.violations, "descriptionCourt") &&
      errors.push(this.violations.descriptionCourt);

      return errors;
    },
    descriptionCompletErrors() {
      const errors = [];

      if (!this.$v.item.descriptionComplet.$dirty) return errors;

      has(this.violations, "descriptionComplet") &&
      errors.push(this.violations.descriptionComplet);

      return errors;
    },
    inviteImportantErrors() {
      const errors = [];

      if (!this.$v.item.inviteImportant.$dirty) return errors;

      has(this.violations, "inviteImportant") &&
      errors.push(this.violations.inviteImportant);

      return errors;
    },
    siteWebErrors() {
      const errors = [];

      if (!this.$v.item.siteWeb.$dirty) return errors;

      has(this.violations, "siteWeb") && errors.push(this.violations.siteWeb);

      return errors;
    },
    facebookErrors() {
      const errors = [];

      if (!this.$v.item.facebook.$dirty) return errors;

      has(this.violations, "facebook") && errors.push(this.violations.facebook);

      return errors;
    },
    twitterErrors() {
      const errors = [];

      if (!this.$v.item.twitter.$dirty) return errors;

      has(this.violations, "twitter") && errors.push(this.violations.twitter);

      return errors;
    },
    youtubeErrors() {
      const errors = [];

      if (!this.$v.item.youtube.$dirty) return errors;

      has(this.violations, "youtube") && errors.push(this.violations.youtube);

      return errors;
    },
    showOnFirstPageErrors() {
      const errors = [];

      if (!this.$v.item.showOnFirstPage.$dirty) return errors;

      has(this.violations, "showOnFirstPage") &&
      errors.push(this.violations.showOnFirstPage);

      return errors;
    },
    imageErrors() {
      const errors = [];

      if (!this.$v.item.image.$dirty) return errors;

      has(this.violations, "image") && errors.push(this.violations.image);

      return errors;
    },

    evenementsErrors() {
      const errors = [];

      if (!this.$v.item.evenements.$dirty) return errors;

      has(this.violations, "evenements") &&
      errors.push(this.violations.evenements);

      return errors;
    },
    intervenantTypeErrors() {
      const errors = [];

      if (!this.$v.item.intervenantType.$dirty) return errors;

      has(this.violations, "intervenantType") &&
      errors.push(this.violations.intervenantType);

      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
    ...mapActions({
      evenementsGetSelectItems: "evenement/fetchSelectItems",
      intervenantTypeGetSelectItems: "intervenantType/fetchSelectItems"
    })
  },
  validations: {
    item: {
      slug: {},
      nom: {},
      motcles: {},
      descriptionCourt: {},
      descriptionComplet: {},
      inviteImportant: {},
      siteWeb: {},
      facebook: {},
      twitter: {},
      youtube: {},
      showOnFirstPage: {},
      intervenantType: {},
      image: {},
      evenements: {},
    }
  }
};
</script>
